import React, {useState} from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast, Slide } from 'react-toastify';




const ContactForm = () => {
  const recaptchaRef = React.createRef();

  const [name,setName] = useState('');
  const [status,setStatus] = useState('SEND MESSAGE');
  const [phone,setPhone] = useState('');
  const [message,setMessage] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [captchaCode, setCaptchaCode] = useState('');



  const encode = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((k)=>{
      formData.append(k,data[k])
    });
    return formData
  }


  const handleSubmit = e => {
    e.preventDefault();
    if (captchaCode === ""){
    alert("please check recaptcha");
    return false;
    }else{
      setStatus("SENDING MESSAGE")
      setButtonDisabled(true);
      sendForm()
    }
  };


  const handleChange = e => {
    const {name, value} = e.target
    if (name === 'name' ){
      return setName(value)
    }
    if (name === 'phone' ){
      return setPhone(value)
    }
    if (name === 'message' ){
      return setMessage(value)
    }
  }

  function onRecaptchaChange(value) {
    setCaptchaCode(value)
  }

  const sendForm = () => {

    const captchaCode = recaptchaRef.current.getValue();

    const data = { "form-name": "The Horbury & Ossett App", name, phone, message, 'g-recaptcha-response': captchaCode};
    const postUrl = 'https://www.roundboxmedia.com.au/mail-horbury-ossett/mail.php';

    fetch(postUrl, {
      method: "POST",
      // headers: { "Content-Type": 'multipart/form-data; boundary=random' },
      body: encode(data)
    })
      .then(function(response){
        if (response.status !== 200) {
            alert('Looks like there was a problem. Status Code: ' + response.status);
          return;
        }else{
          setButtonDisabled(false);
          setStatus('SEND MESSAGE');
          setName('');
          setPhone('');
          setMessage('');
            notify();
          window.grecaptcha.reset();
        }
      })
      .catch(error => {
        setStatus("Form Submission Failed!")
        setButtonDisabled(false);
      });
  }


  const notify = () => toast('Message Sent', {
    position: "top-center",
    autoClose: 5000,
    transition: Slide,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });


  return (

    <div className="contactForm" style={{padding:'10px'}}>
    <ToastContainer />
    <form
    onSubmit={handleSubmit}
    action="/thank-you/"
    data-netlify="true"
    data-netlify-recaptcha="true"
    data-netlify-honeypot="bot-field">
    <input name="contact" value="contact" type="hidden" />
    <p hidden>
      <label>
        Don’t fill this out:{" "}
        <input name="bot-field" onChange={handleChange} />
      </label>
    </p>
          <input   style={{
            padding: '10px',
            width: '100%',
            fontSize: '1rem',
            outline: '0',
            marginTop:'15px',
            border: '1px solid #e3e3e3',
            font: 'inherit',
          }}
          type="text"
          name="name"
          placeholder="Full Name"
          value={name}
          onChange={handleChange}
          required/>

          <input
          style={{
            padding: '10px',
            width: '100%',
            fontSize: '1rem',
            outline: '0',
            marginTop:'15px',
            border: '1px solid #e3e3e3',
            font: 'inherit',
          }}
          type="phone"
          name="phone"
          placeholder="Phone or email"
          value={phone}
          onChange={handleChange}
          required
          />


          <textarea
          style={{
            padding: '10px',
            width: '100%',
            fontSize: '1rem',
            outline: '0',
            minHeight: '250px',
            resize: 'vertical',
            marginTop:'15px',
            border: '1px solid #e3e3e3',
            font: 'inherit',
          }}
          name="message"
          placeholder="Message"
          value={message}
          onChange={handleChange}
          required
          />

           <div style={{margin:'0 auto', width: '310px', marginTop: '25px'}}>
             <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY}
              onChange={onRecaptchaChange}
              required
            />
          </div>
          <p>
            <button
              style={{
                margin: '25px auto',
                background: '#8fc744',
                color: '#ffffff',
                outline: '0',
                border: '0',
                WebkitAppearance: 'none',
                display: 'block',
                padding: '15px',
                textTransform: 'uppercase',
                fontSize: '1rem',
                minWidth: '200px',
              }}
            disabled={buttonDisabled}
            type="submit">{status}</button>
          </p>
        </form>
    </div>
  );
}

export default ContactForm;
